/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AuthActions } from '@midas/shared/data-access';
import { UiStatusBlockComponent } from '@midas/shared/ui';
import { RxJSBaseClass, UtilHttpProcessingOverlayDirective } from '@midas/shared/util';
import { TranslocoModule } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs';

export interface VerifySetNewPasswordDialogComponentData {
  username: string;
  session: string;
  newPassword: string;
}

@Component({
  selector: 'midas-verify-set-new-password-dialog',
  templateUrl: './verify-set-new-password-dialog.component.html',
  styleUrls: ['./verify-set-new-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    DragDropModule,
    MatButtonModule,
    UiStatusBlockComponent,
    TranslocoModule,
    UtilHttpProcessingOverlayDirective,
  ],
})
export class VerifySetNewPasswordDialogComponent extends RxJSBaseClass implements OnInit {
  readonly verificationCodeForm = new FormGroup({
    code: new FormControl<string>('', { validators: [Validators.required] }),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: VerifySetNewPasswordDialogComponentData,
    private dialogRef: MatDialogRef<VerifySetNewPasswordDialogComponent>,
    private store: Store,
    private actions: Actions
  ) {
    super();
  }

  ngOnInit(): void {
    this.actions
      .pipe(ofType(AuthActions.verifySetNewPasswordSuccess), takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  verifyCode(): void {
    this.store.dispatch(
      AuthActions.verifySetNewPassword({
        code: this.verificationCodeForm.controls.code.value || '',
        session: this.data.session,
        username: this.data.username,
        newPassword: this.data.newPassword,
      })
    );
  }
}
