/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { AbstractControl, ValidationErrors } from '@angular/forms';

export function matchPasswordValidator(control: AbstractControl): ValidationErrors | null {
  const password = control.get('password')?.value;
  const confirmPassword = control.get('confirmPassword')?.value;
  const rtn = password === confirmPassword ? null : { notSame: true };
  control.get('confirmPassword')?.setErrors(rtn);
  return rtn;
}
