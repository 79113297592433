/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, InitLoaderService, LocalStorageAuthTokenKey, UiActions } from '@midas/shared/common';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { pessimisticUpdate } from '@nrwl/angular';
import { map, tap } from 'rxjs';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
  readonly login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      pessimisticUpdate({
        run: (dto) =>
          this.api.post<{ token: string; action: string; session: string }>('/auth/login', dto).pipe(
            map(({ token, action, session }) => {
              if (action === 'NEW_PASSWORD_REQUIRED') {
                return AuthActions.loginRequiresPasswordUpdate({
                  username: dto.username,
                  currentTemporaryPassword: dto.password,
                });
              } else if (action === 'SMS_MFA') {
                return AuthActions.loginRequiresMfaCode({
                  username: dto.username,
                  session: session,
                });
              }
              this.initLoader.load();
              LocalStorageAuthTokenKey.authToken = token;
              this.router.navigate(['']);
              return AuthActions.loginSuccess({ token });
            })
          ),
        onError: (action, error) => {
          return AuthActions.loginError({ error });
        },
      })
    )
  );

  readonly logoutLocal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutLocal),
        tap(() => {
          LocalStorageAuthTokenKey.deleteToken();
          window.location.href = '/login';
        })
      ),
    { dispatch: false }
  );

  readonly setNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.setNewPassword),
      pessimisticUpdate({
        run: (dto) =>
          this.api
            .post<{ token: string; action: string; session: string }>('/auth/set-new-password', dto)
            .pipe(
              map(({ token, action, session }) => {
                if (action === 'SMS_MFA') {
                  return AuthActions.setNewPasswordRequiresMfa({
                    username: dto.username,
                    session: session,
                    newPassword: dto.newPassword,
                  });
                }

                LocalStorageAuthTokenKey.authToken = token;
                this.store.dispatch(
                  UiActions.setGlobalSuccess({
                    success: this.transloco.translate('Password updated successfully'),
                  })
                );
                return AuthActions.setNewPasswordSuccess();
              })
            ),
        onError: (action, error) => {
          return AuthActions.setNewPasswordError({ error });
        },
      })
    )
  );

  readonly verifySetNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.verifySetNewPassword),
      pessimisticUpdate({
        run: (dto) =>
          this.api.post<{ token: string }>('/auth/verify-set-new-password', dto).pipe(
            map(({ token }) => {
              LocalStorageAuthTokenKey.authToken = token;
              this.store.dispatch(
                UiActions.setGlobalSuccess({
                  success: this.transloco.translate('Password updated successfully'),
                })
              );
              return AuthActions.verifySetNewPasswordSuccess();
            })
          ),
        onError: (action, error) => {
          return AuthActions.verifySetNewPasswordError({ error });
        },
      })
    )
  );

  readonly forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.forgotPassword),
      pessimisticUpdate({
        run: (dto) =>
          this.api
            .post<{ deliveryMedium: string; destination: string; username: string }>(
              '/auth/forgot-password',
              dto
            )
            .pipe(
              map((res) => {
                return AuthActions.forgotPasswordSuccess(res);
              })
            ),
        onError: (action, error) => {
          return AuthActions.forgotPasswordError({ error });
        },
      })
    )
  );

  readonly confirmForgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.confirmForgotPassword),
      pessimisticUpdate({
        run: (dto) =>
          this.api
            .post<{ token: string; action: string; session: string }>('/auth/confirm-forgot-password', dto)
            .pipe(
              map(({ action, session, token }) => {
                if (action === 'SMS_MFA') {
                  return AuthActions.setNewPasswordRequiresMfa({
                    username: dto.username,
                    session: session,
                    newPassword: dto.password,
                  });
                }
                LocalStorageAuthTokenKey.authToken = token;
                this.router.navigate(['/']);
                return AuthActions.confirmForgotPasswordSuccess({ token });
              })
            ),
        onError: (action, error) => {
          return AuthActions.confirmForgotPasswordError({ error });
        },
      })
    )
  );

  readonly verifyMfaCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.verifyMfaCode),
      pessimisticUpdate({
        run: (dto) =>
          this.api.post<{ token: string }>('/auth/verify-mfa-code', dto).pipe(
            map((res) => {
              LocalStorageAuthTokenKey.authToken = res.token;
              return AuthActions.verifyMfaCodeSuccess(res);
            })
          ),
        onError: (action, error) => {
          return AuthActions.verifyMfaCodeError({ error });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private router: Router,
    private initLoader: InitLoaderService,
    private store: Store,
    private transloco: TranslocoService
  ) {}
}
