/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, Organization, UiActions } from '@midas/shared/common';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch, pessimisticUpdate } from '@nrwl/angular';
import { map } from 'rxjs';
import { UserActions } from '../user';
import * as CurrentOrgActions from './current-org.actions';

@Injectable()
export class CurrentOrgEffects {
  readonly getCurrentOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentOrgActions.getCurrentOrg),
      fetch({
        id: () => true,
        run: ({ organizationName }) => {
          return this.api
            .get<Organization>('/organization/current', undefined, { 'X-Organization': organizationName })
            .pipe(
              map((org) => {
                return CurrentOrgActions.getCurrentOrgSuccess({ org });
              })
            );
        },
        onError: (action, error) => {
          return CurrentOrgActions.getCurrentOrgError({ error, showInDialog: true });
        },
      })
    )
  );

  readonly updateCurrentOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurrentOrgActions.updateCurrentOrg),
      pessimisticUpdate({
        run: (dto) =>
          this.api.put<Organization>('/organization', dto).pipe(
            map((org) => {
              const newUrl = this.router.url.replace(/\/org\/(.*?)\//, `/org/${org.name}/`);
              this.router.navigateByUrl(newUrl);
              this.store.dispatch(UserActions.refreshProfile());
              this.store.dispatch(
                UiActions.setGlobalSuccess({
                  success: this.transloco.translate('Organization updated successfully'),
                })
              );
              return CurrentOrgActions.updateCurrentOrgSuccess({ org });
            })
          ),
        onError: (action, error) => {
          return CurrentOrgActions.updateCurrentOrgError({ error });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private router: Router,
    private store: Store,
    private transloco: TranslocoService
  ) {}
}
