<!-- Copyright 2023 Midas Healthcare Solutions - All Rights Reserved -->
<ng-container *transloco="let t">
  <div
    class="ui-dialog-top-bar"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container"
    cdkDragHandle
  >
    <span class="header">
      <mat-icon color="primary">lock</mat-icon>
      <span>
        {{ t('Reset Password') }}
      </span>
    </span>

    <div class="grow"></div>
  </div>

  <midas-ui-status-block [appearance]="'inline'"></midas-ui-status-block>

  <form [formGroup]="verificationCodeForm">
    <div class="header">{{ t('Enter the verification code sent to your 2FA phone number.') }}</div>

    <mat-form-field appearance="fill">
      <mat-label>{{ t('Verification Code') }}</mat-label>
      <input matInput autocomplete="null" formControlName="code" />
      <mat-error>{{ t('Verification Code is required.') }}</mat-error>
    </mat-form-field>

    <button
      mat-flat-button
      color="primary"
      utilProcessing
      [disabled]="verificationCodeForm.invalid"
      (click)="verifyCode()"
      type="submit"
    >
      {{ t('Reset Password') }}
    </button>
  </form>
</ng-container>
