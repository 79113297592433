/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Directive, ElementRef, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { filter, takeUntil } from 'rxjs';
import { RxJSBaseClass } from './rxjs-base-class';

// Holds each instance of the directives to make sure only one `processing` is true.
const refs: UtilHttpProcessingOverlayDirective[] = [];

/**
 * This directive is used to overlay a loading spinner when an HTTP request is in progress
 * after a user clicks on an element.
 */
@Directive({
  selector: '[utilProcessing]',
  standalone: true,
})
export class UtilHttpProcessingOverlayDirective extends RxJSBaseClass implements OnInit, OnDestroy {
  processing = false;

  constructor(private loader: LoadingBarService, private renderer: Renderer2, private el: ElementRef) {
    super();
    refs.push(this);
  }

  ngOnInit(): void {
    this.loader
      .useRef('http')
      .value$.pipe(
        filter(() => this.processing),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        if (value === 0 || value === 100) {
          this.renderer.removeClass(this.el.nativeElement, 'ui-processing');
          this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
        } else {
          this.renderer.addClass(this.el.nativeElement, 'ui-processing');
          this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
        }
      });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    refs.splice(refs.indexOf(this), 1);
  }

  set() {
    this.renderer.addClass(this.el.nativeElement, 'ui-processing');
    this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
  }

  @HostListener('click')
  onClick(): void {
    refs.forEach((ref) => (ref.processing = false));
    this.processing = true;
  }
}
