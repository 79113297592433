/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UiActions, UiSelectors, UiTheme } from '@midas/shared/common';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

@Component({
  selector: 'midas-ui-theme-toggle',
  templateUrl: './ui-theme-toggle.component.html',
  styleUrls: ['./ui-theme-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, AsyncPipe, MatButtonModule, MatIconModule, MatTooltipModule],
})
export class UiThemeToggleComponent {
  readonly theme$ = this.store.select(UiSelectors.getUiState).pipe(map((state) => state.theme));

  constructor(private store: Store) {}

  setTheme(currentTheme: UiTheme) {
    const theme = currentTheme === 'dark' ? 'light' : 'dark';
    this.store.dispatch(UiActions.setTheme({ theme }));
  }
}
