/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Directive, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { RxJSBaseClass } from '@midas/shared/util';
import { filter, Observable, startWith, switchMap, take, takeUntil } from 'rxjs';

@Directive()
export abstract class IQuerySelect<T extends { id: number }>
  extends RxJSBaseClass
  implements OnInit, ControlValueAccessor
{
  readonly searchCtrl = new FormControl('');

  filteredItems!: Observable<T[]>;

  items: T[] = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;

  changeFn!: (val: number[] | null) => unknown;

  abstract getItemsBySearchString(searchString?: string | null): Observable<T[]>;
  abstract getItemsByIds(ids: number[]): Observable<T[]>;

  constructor(private change: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.filteredItems = this.searchCtrl.valueChanges.pipe(
      filter((str) => typeof str === 'string'),
      startWith(null),
      switchMap((str) => this.getItemsBySearchString(str))
    );
  }

  remove(item: T): void {
    const index = this.items.findIndex((c) => c.id === item.id);

    if (index >= 0) {
      this.items.splice(index, 1);
    }

    this.changeFn(this.items.map((l) => l.id));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const val = event.option.value as T;
    if (!this.items.some((c) => c.id === val.id)) {
      this.items.push(val);
    }
    this.searchInput.nativeElement.value = '';
    this.searchCtrl.setValue(null);
    this.changeFn(this.items.map((l) => l.id));
  }

  writeValue(ids: number[]): void {
    if (!ids || ids.length === 0) {
      this.items = [];
      return;
    }

    this.getItemsByIds(ids)
      .pipe(take(1))
      .subscribe((items) => {
        this.items = items;
        this.change.markForCheck();
      });
  }

  registerOnChange(fn: (val: number[] | null) => unknown): void {
    this.changeFn = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnTouched(fn: any): void {
    this.searchCtrl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.searchCtrl.disable();
    } else {
      this.searchCtrl.enable();
    }
  }
}
