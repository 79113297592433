<!-- Copyright 2023 Midas Healthcare Solutions - All Rights Reserved -->
<ng-container *transloco="let t">
  <div
    class="ui-dialog-top-bar"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container"
    cdkDragHandle
  >
    <span class="header">
      <mat-icon color="primary">notification_important</mat-icon>
      <span>{{ t('Your session is about to expire.') }}</span>
    </span>
  </div>

  <midas-ui-status-block [appearance]="'inline'"></midas-ui-status-block>

  <form>
    {{ t('For your security, this session will expire in') }}
    <b>{{ countdownText }}</b>
    {{
      t(
        "due to inactivity. If you want to extend your session, please select the 'Continue' button. If you select the 'Log Out' button or do not respond, your session will automatically close."
      )
    }}

    <button mat-flat-button color="primary" utilProcessing (click)="continue()">
      {{ t('Continue') }}
    </button>

    <button mat-flat-button color="primary" utilProcessing (click)="logout()">
      {{ t('Log Out') }}
    </button>
  </form>
</ng-container>
