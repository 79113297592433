/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { IWorkflow } from '@midas/shared/common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'midas-video-thumbnail',
  template: `
    <ng-container *transloco="let t">
      <div class="video-container" *ngIf="workflow?.videoUploadComplete && src">
        <img [width]="width" [src]="src"/>
      </div>

      <div class="video-container" *ngIf="!workflow?.videoUploadComplete">
        <img [width]="width" [height]="height" src="/assets/test_pattern.png" alt="Uploading" />
        <div class="video-centered">{{ t('Uploading...') }}</div>
      </div>

      <div class="video-container" *ngIf="videoCheckSumFail">
        <img [width]="width" [height]="height" src="/assets/static.png" alt="Error" />
        <div class="video-centered">{{ t('Error') }}</div>
      </div>
    </ng-container>
  `,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, TranslocoModule],
})
export class VideoThumbnailComponent {
  @Input() workflow?: IWorkflow;
  @Input() videoCheckSumFail = false;
  @Input() width = 220;
  @Input() height = 140;
  @Input() src = '';
}
