/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Injectable } from '@angular/core';
import { CanMatch, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable, take } from 'rxjs';
import { UserSelectors } from '../stores/user';

@Injectable({ providedIn: 'root' })
export class MidasAdminGuard implements CanMatch {
  constructor(private store: Store, private router: Router) {}

  canMatch(): Observable<boolean | UrlTree> {
    return this.store.select(UserSelectors.getUserState).pipe(
      take(1),
      map(({ midasAdmin }) => midasAdmin || this.router.createUrlTree(['/']))
    );
  }
}
