<!-- Copyright 2023 Midas Healthcare Solutions - All Rights Reserved -->
<mat-form-field class="example-chip-list" appearance="fill" *transloco="let t">
  <mat-label>{{ t('Filter by Patient') }}</mat-label>
  <mat-chip-grid #chipGrid>
    <mat-chip-row *ngFor="let patient of items" (removed)="remove(patient)">
      {{ patient.nameLast }}, {{ patient.nameFirst }} {{ patient.nameMiddle }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input
    #searchInput
    [formControl]="searchCtrl"
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let patient of filteredItems | async" [value]="patient">
      {{ patient.nameLast }}, {{ patient.nameFirst }} {{ patient.nameMiddle }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
