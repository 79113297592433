/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IWorkflow } from '@midas/shared/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { VideoThumbnailComponent } from './video-thumbnail.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const videojs = (window as any).videojs;

@Component({
  selector: 'midas-vjs-player',
  template: `
    <video
      #target
      class="video-js"
      [style.display]="(!workflow?.videoUploadComplete || videoCheckSumFail) ? 'none' : 'block'"
      crossorigin="anonymous"
      controls
      muted
      playsinline
      [preload]="preload"
      (error)="handleVideoError($event)"
    ></video>

    <midas-video-thumbnail
      [workflow]="workflow"
      [videoCheckSumFail]="videoCheckSumFail"
      [width]="width"
      [height]="height"/>

    <ng-container *ngIf="showVideoError">
      <div>{{ videoLoadErrorMessage }}</div>
      <div>{{ videoLoadErrorDetail }}</div>
    </ng-container>
  `,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, TranslocoModule, VideoThumbnailComponent],
})
export class VjsPlayerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('target', { static: true }) target!: ElementRef<HTMLVideoElement>;

  @Input() videoUploadComplete = true;
  @Input() preload: 'auto' | 'metadata' = 'metadata';

  @Input() @HostBinding('style.width.px') width = 220;
  @Input() @HostBinding('style.height.px') height = 140;

  @Output() videoFailedEvent = new EventEmitter();
  @Output() metadataLoaded = new EventEmitter<{ duration: number }>();

  // See options: https://videojs.com/guides/options

  @Input() fluid = false;
  @Input() aspectRatio = '16:9';
  @Input() autoplay = false;
  @Input() controls = true;
  @Input() src = '';
  @Input() type = 'video/mp4';
  @Input() workflow?: IWorkflow;
  @Input() checkFor360Video = false;  // Only Wasting, Transport, and Final Wasting videos should be checked for 360
  @Input() videoCheckSumFail = false;
  @Input() inactivityTimeout = 0; // No inactivity timeout treats the user as always active. Controls will not hide.

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  player: any;

  showVideoError = false;
  videoLoadErrorMessage = '';
  videoLoadErrorDetail = '';

  constructor(private transloco: TranslocoService) {}

  ngAfterViewInit() {
    this.player = videojs(
      this.target.nativeElement,
      {
        fluid: this.fluid,
        aspectRatio: this.aspectRatio,
        autoplay: this.autoplay,
        controls: this.controls,
        sources: [{ src: this.src, type: this.type }],
        inactivityTimeout: this.inactivityTimeout,
      },
      function onPlayerReady() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        console.log('onPlayerReady', this);
      },
    );

    this.target.nativeElement.addEventListener('loadedmetadata', (e) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const evt = e as any;

      if (this.checkFor360Video && this.workflow?.is360Video) {
        this.player.vr({ projection: 'Sphere' });
      }

      this.metadataLoaded.emit({ duration: evt.target.duration });
    });
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }

  handleVideoError(e: ErrorEvent): void {
    this.showVideoError = true;
    this.videoFailedEvent.emit(true);
    this.videoLoadErrorMessage = this.transloco.translate(
      "An error occurred while attempting to load / play the video.  Review the waste at another time or 'Flag for Further Review'.",
    );

    if (!e) {
      this.videoLoadErrorDetail =
        this.transloco.translate('Error Detail') + ': ' + this.transloco.translate('Unknown error');
      return;
    }

    const video = e.target as HTMLVideoElement;
    this.videoLoadErrorDetail = this.transloco.translate('Error Detail') + ': ';

    switch (video.error?.code) {
      case MediaError.MEDIA_ERR_ABORTED:
        this.videoLoadErrorDetail =
          this.videoLoadErrorDetail + this.transloco.translate('Media fetch was aborted by user.');
        break;
      case MediaError.MEDIA_ERR_NETWORK:
        this.videoLoadErrorDetail =
          this.videoLoadErrorDetail +
          this.transloco.translate('Media source no longer available due to network issue.');
        break;
      case MediaError.MEDIA_ERR_DECODE:
        this.videoLoadErrorDetail =
          this.videoLoadErrorDetail + this.transloco.translate('Media source decode error.');
        break;
      case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
        this.videoLoadErrorDetail =
          this.videoLoadErrorDetail + this.transloco.translate('Media source unavailable or unable to load.');
        break;
      default:
        this.videoLoadErrorDetail =
          this.videoLoadErrorDetail +
          this.transloco.translate('Unknown Media Error Code') +
          ': ' +
          video.error?.code;
    }
  }
}
