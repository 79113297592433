/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SortCriteriaItem } from '@midas/shared/common';
import { RxJSBaseClass } from '@midas/shared/util';
import { TranslocoModule } from '@ngneat/transloco';

export const defaultSortCriteria = <T>(typeEnum: T, ascending = false): SortCriteriaItem<T>[] =>
  Object.values(typeEnum as object)
    .filter((c): c is T => typeof c === 'number')
    .map((c) => ({
      ascending,
      criteria: c,
    }));

@Component({
  selector: 'midas-ui-sort[typeEnum]',
  templateUrl: './ui-sort.component.html',
  styleUrls: ['./ui-sort.component.scss'],
  standalone: true,
  imports: [CommonModule, DragDropModule, TranslocoModule, MatIconModule, MatButtonModule],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiSortComponent),
      multi: true,
    },
  ],
})
export class UiSortComponent extends RxJSBaseClass implements ControlValueAccessor {
  @Input() typeEnum = {};

  criteria: SortCriteriaItem[] = [...defaultSortCriteria(this.typeEnum)];

  changeFn!: (val: SortCriteriaItem[] | null) => unknown;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  writeValue(obj: any): void {
    this.criteria = [...obj].map((c) => ({ ...c }));
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.criteria, event.previousIndex, event.currentIndex);
    this.changeFn(this.criteria);
  }

  toggleAscending(index: number) {
    this.criteria[index].ascending = !this.criteria[index].ascending;
    this.changeFn(this.criteria);
  }

  registerOnChange(fn: (val: SortCriteriaItem[] | null) => unknown): void {
    this.changeFn = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  registerOnTouched(fn: unknown): void {
    // Do nothing
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setDisabledState?(isDisabled: boolean): void {
    // Do nothing
  }
}
