<!-- Copyright 2023 Midas Healthcare Solutions - All Rights Reserved -->
<ng-container *ngIf="error">
  <div class="cont error" *transloco="let t">
    <mat-icon color="warn">error</mat-icon>
    <div class="error">
      <b>{{ error.error?.title || t('Unknown Error') }}</b>
      <div class="detail" *ngIf="error.error?.detail as detail" [innerHtml]="detail"></div>
    </div>
    <button mat-icon-button aria-label="Close Error Box" (click)="clear()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</ng-container>

<div class="cont success" *ngIf="success">
  <div class="success">
    <mat-icon color="accent">check_circle</mat-icon>
    <span>{{ success }}</span>
  </div>
  <button mat-icon-button aria-label="Close Success Box" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</div>
