/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { MidasEffectError, UserLocationPermission } from '@midas/shared/common';
import { createAction, props } from '@ngrx/store';

export const getUserLocationPermissions = createAction('[Permissions] Get User Location Permissions');
export const getUserLocationPermissionsSuccess = createAction(
  '[Permissions] Get User Location Permissions Success',
  props<{
    userPermissions: UserLocationPermission[];
  }>()
);
export const getUserLocationPermissionsError = createAction(
  '[Permissions] Get User Location Permissions Error',
  props<MidasEffectError>()
);
