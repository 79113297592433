/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, merge, Observable, of, switchMap, take } from 'rxjs';
import { CurrentOrgActions, CurrentOrgSelectors } from '../stores/current-org';
import { UserSelectors } from '../stores/user';

let previousOrg = '';

/**
 * This guard ensures that the current organization is loaded before the route is activated.
 * This is especially important when a MIDAS admin logins into another organization.
 */
@Injectable({ providedIn: 'root' })
export class OrganizationGuard implements CanMatch {
  constructor(private store: Store, private router: Router, private actions: Actions) {}

  canMatch(_: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    // Example `midas.com/org/UPMC`
    // `org` is the first segment, and `UPMC` is the second segment.
    const attemptedOrgName = segments[1].path;

    return this.store.select(UserSelectors.getUserState).pipe(
      switchMap(({ organizationName }) =>
        merge(
          // EDGE CASE 1:
          // The provided organization name in the router segment is empty string.
          // Then redirect to the previously used organization or user's organization if empty.
          of(attemptedOrgName).pipe(
            filter((segment) => !segment),
            map(() => this.router.createUrlTree(['org', previousOrg || organizationName]))
          ),
          // EDGE CASE 2:
          // The provided organization name in the router is an organization that does not exist,
          // or the user does is not permitted to access the organization.
          // The `getCurrentOrgError` action will be dispatched because of the server's validation of the X-Organization header.
          // Then redirect to the user's organization.
          this.actions
            .pipe(ofType(CurrentOrgActions.getCurrentOrgError))
            .pipe(map(() => this.router.createUrlTree(['org', organizationName]))),
          // NORMAL CASE:
          // The provided organization name in the router is a valid organization name,
          // and the user is permitted to access to the organization
          // (i.e. the user is a member of the organization or the user is a MIDAS admin).
          // Then the `getCurrentOrgSuccess` action will be dispatched and the current org state will be loaded and set.
          this.store.select(CurrentOrgSelectors.getCurrentOrgState).pipe(
            filter(({ loadedOrgs }) => {
              previousOrg = attemptedOrgName;
              if (!loadedOrgs.includes(attemptedOrgName)) {
                this.store.dispatch(CurrentOrgActions.getCurrentOrg({ organizationName: attemptedOrgName }));
                return false;
              }
              this.store.dispatch(CurrentOrgActions.setCurrentOrg({ organizationName: attemptedOrgName }));
              return true;
            }),
            map(() => true)
          )
        )
      ),
      take(1)
    );
  }
}
