/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RouterOutlet } from '@angular/router';
import { LOCAL_STORAGE_AUTH_TOKEN_KEY, UiService } from '@midas/shared/common';
import { AuthActions } from '@midas/shared/data-access';
import { RxJSBaseClass } from '@midas/shared/util';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { switchMap, takeUntil } from 'rxjs';
import {
  VerifySetNewPasswordDialogComponent,
  VerifySetNewPasswordDialogComponentData,
} from './verify-set-new-password-dialog/verify-set-new-password-dialog.component';

@Component({
  selector: 'midas-root',
  template: '<router-outlet></router-outlet>',
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent extends RxJSBaseClass implements OnInit {
  constructor(
    private ui: UiService,
    private store: Store,
    private actions: Actions,
    private dialog: MatDialog,
    private loadingBar: LoadingBarService
  ) {
    super();
  }

  ngOnInit(): void {
    this.ui.listenTheme();

    // If the user logs out in another tab, we need to log them out here too.
    // IF the user logs in in another tab, we need to reload the page.
    window.addEventListener('storage', (event) => {
      if (event.storageArea != localStorage) {
        return;
      }
      if (event.key === LOCAL_STORAGE_AUTH_TOKEN_KEY) {
        if (event.newValue == null) {
          this.store.dispatch(AuthActions.logoutLocal());
        } else {
          window.location.reload();
        }
      }
    });

    this.actions
      .pipe(
        ofType(AuthActions.setNewPasswordRequiresMfa),
        switchMap(({ newPassword, session, username }) => {
          this.loadingBar.useRef('router').start();
          const data: VerifySetNewPasswordDialogComponentData = {
            session,
            username,
            newPassword,
          };
          const ref = this.dialog.open(VerifySetNewPasswordDialogComponent, {
            data,
            disableClose: true,
            width: '400px',
            panelClass: 'ui-dialog-window',
          });
          return ref.afterOpened().pipe(
            switchMap(() => {
              this.loadingBar.useRef('router').complete();
              return ref.afterClosed();
            })
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
