/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
export const agGridDateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    const cellDate = new Date(cellValue);
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
};

export const onlyDateFilterParams = {
  comparator: (filterValue: Date, cellValue: string) => {
    const cellDate = new Date(cellValue);
    const day = cellDate.getDate();
    const month = cellDate.getMonth();
    const year = cellDate.getFullYear();
    const filterDay = filterValue.getDate();
    const filterMonth = filterValue.getMonth();
    const filterYear = filterValue.getFullYear();

    return (year > filterYear)   ? 1 : (year < filterYear)   ? -1 :
           (month > filterMonth) ? 1 : (month < filterMonth) ? -1 :
           (day > filterDay)     ? 1 : (day < filterDay)     ? -1 : 0;
  }
};
