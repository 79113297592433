/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
/* eslint-disable @typescript-eslint/no-explicit-any */
import { NgTemplateOutlet } from '@angular/common';
import { Component, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'util-template-renderer',
  template: `
    <ng-container *ngTemplateOutlet="template; context: templateContext"></ng-container>
  `,
  styles: [
    `
      :host {
        position: absolute;
        height: inherit;
        display: grid;
        align-content: center;
      }
    `,
  ],
  standalone: true,
  imports: [NgTemplateOutlet],
})
export class TemplateRendererComponent implements ICellRendererAngularComp {
  template!: TemplateRef<unknown>;
  templateContext!: { $implicit: unknown; params: unknown };

  params!: ICellRendererParams;

  refresh(params: ICellRendererParams): boolean {
    this.templateContext = {
      $implicit: params,
      params: params,
    };
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.template = (params as any)['ngTemplate'];
    this.refresh(params);
  }
}
