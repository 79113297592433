/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, take } from 'rxjs';
import { UtilConfirmDialogComponent, UtilConfirmDialogData } from './util-confirm-dialog.component';

@Injectable({ providedIn: 'root' })
export class UtilConfirmDialogService {
  constructor(private dialog: MatDialog) {}

  open(data: UtilConfirmDialogData, disableClose = false): Observable<number> {
    return this.dialog.open(UtilConfirmDialogComponent, { data, disableClose }).afterClosed().pipe(take(1));
  }
}

export { UtilConfirmDialogData } from './util-confirm-dialog.component';
