/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, filter, Subject, take } from 'rxjs';
import { InitLoaderComponent } from './init-loader.component';

@Injectable({ providedIn: 'root' })
export class InitLoaderService {
  overlayRef?: OverlayRef;

  constructor(private overlay: Overlay, private router: Router) {}

  load() {
    this.overlayRef = this.overlay.create();

    const debounce$ = new Subject<void>();

    const debounceSub = debounce$.pipe(debounceTime(200), take(1)).subscribe(() => {
      this.overlayRef?.attach(new ComponentPortal(InitLoaderComponent));
    });

    debounce$.next();

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        debounceSub.unsubscribe();
        this.overlayRef?.detach();
      });
  }

  cancel() {
    this.overlayRef?.detach();
    this.overlayRef = undefined;
  }
}
