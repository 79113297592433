<!-- Copyright 2023 Midas Healthcare Solutions - All Rights Reserved -->
<mat-form-field class="example-chip-list" appearance="fill" *transloco="let t">
  <mat-label>{{ t('Filter by Formulary') }}</mat-label>
  <mat-chip-grid #chipGrid>
    <mat-chip-row *ngFor="let f of items" (removed)="remove(f)">
      {{ f.supplyGenericName }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input
    #searchInput
    [formControl]="searchCtrl"
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let f of filteredItems | async" [value]="f">
      {{ f.supplyGenericName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
