<!-- Copyright 2023 Midas Healthcare Solutions - All Rights Reserved -->
<ng-container *transloco="let t">
  <div class="header">
    {{ t('Sort Criteria') }}
  </div>
  <div class="ui-sort-drag-list" cdkDropList (cdkDropListDropped)="drop($event)">
    <div cdkDrag class="ui-sort-drag-item" *ngFor="let c of criteria; index as i">
      <mat-icon cdkDragHandle>drag_indicator</mat-icon>
      <div [style.flex]="1">
        {{ $any(this.typeEnum)[$any(c.criteria)] }}
      </div>
      <button mat-icon-button (click)="toggleAscending(i)">
        <mat-icon cdkDragHandle>{{ c.ascending ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
