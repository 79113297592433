/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
export const LOCAL_STORAGE_AUTH_TOKEN_KEY = 'midas-token';

class ILocalStorageAuthTokenKey {
  get authToken() {
    return localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY) || '';
  }

  set authToken(token: string) {
    localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, token);
  }

  deleteToken() {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);
  }
}

export const LocalStorageAuthTokenKey = new ILocalStorageAuthTokenKey();
