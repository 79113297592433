/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { User } from '@midas/shared/common';
import { createFeature, createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';

export const USER_KEY = 'user';

export interface UserState extends User {
  loaded: boolean;
}

const initialState = { loaded: false } as UserState;

export const UserFeature = createFeature({
  name: USER_KEY,
  reducer: createReducer(
    initialState,
    on(UserActions.getProfileSuccess, (state, { user }): UserState => {
      return { ...state, ...user, loaded: true };
    }),
    on(UserActions.refreshProfile, (state): UserState => {
      return { ...state, loaded: false };
    }),
    on(UserActions.verifyUserSmsMfaSuccess, (state, { user }): UserState => {
      return { ...state, ...user };
    }),
    on(UserActions.verifyDisableUserMfaSuccess, (state, { user }): UserState => {
      return { ...state, ...user };
    }),
    on(UserActions.updateProfileSuccess, (state, user): UserState => {
      return { ...state, ...user, loaded: false };
    })
  ),
});
