/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Injectable } from '@angular/core';
import { CanMatch } from '@angular/router';
import { LocalStorageAuthTokenKey } from '@midas/shared/common';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, merge, of } from 'rxjs';
import { UserActions, UserSelectors } from '../stores/user';

/**
 * Guard whether user should be able to access routes where user must be logged in.
 * If user is logged in, allow access to route.
 * If user is not logged in, redirect to `/login`.
 *
 * This guard ensures that the user profile is loaded before allowing access to the route and the route's children.
 * Therefore, no other calls to load the user profile should be made in the route's children.
 */
@Injectable({ providedIn: 'root' })
export class ShellGuard implements CanMatch {
  constructor(private store: Store, private actions: Actions) {}

  canMatch() {
    const token = LocalStorageAuthTokenKey.authToken;
    if (!token) {
      return of(false);
    }

    return merge(
      this.actions.pipe(ofType(UserActions.getProfileError)).pipe(map(() => false)),
      this.store.select(UserSelectors.getUserState).pipe(
        filter(({ loaded }) => {
          if (!loaded) {
            this.store.dispatch(UserActions.getProfile());
            return false;
          }
          return true;
        }),
        map(() => true)
      )
    );
  }
}
