/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { HttpHeaderInterceptor } from './interceptors/http-header.interceptor';
import { AuthEffects } from './stores/auth/auth.effects';
import { AuthFeature } from './stores/auth/auth.reducer';
import { CurrentOrgEffects } from './stores/current-org/current-org.effects';
import { CurrentOrgFeature } from './stores/current-org/current-org.reducer';
import { UserLocationPermissionsEffects } from './stores/user-location-permissions/user-location-permissions.effects';
import { UserPermissionsFeature } from './stores/user-location-permissions/user-location-permissions.reducer';
import { UserEffects } from './stores/user/user.effects';
import { UserFeature } from './stores/user/user.reducer';

export function provideSharedDataAccess() {
  return [
    importProvidersFrom(OverlayModule),
    provideState(AuthFeature),
    provideState(UserFeature),
    provideState(CurrentOrgFeature),
    provideState(UserPermissionsFeature),
    provideEffects([AuthEffects, UserEffects, CurrentOrgEffects, UserLocationPermissionsEffects]),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ];
}
