/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { createReducer, on, createFeature } from '@ngrx/store';
import * as AuthActions from './auth.actions';

export const AUTH_KEY = 'auth';

export interface AuthState {
  authenticated: boolean;
}

const initialState: AuthState = {
  authenticated: false,
};

export const AuthFeature = createFeature({
  name: AUTH_KEY,
  reducer: createReducer(
    initialState,
    on(AuthActions.loginSuccess, (): AuthState => {
      return { authenticated: true };
    })
  ),
});
