/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiError, InitLoaderService, LocalStorageAuthTokenKey, UiActions } from '@midas/shared/common';
import { UtilConfirmDialogService } from '@midas/shared/util';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private initLoader: InitLoaderService,
    private confirm: UtilConfirmDialogService,
    private transloco: TranslocoService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: ApiError) => {
        this.initLoader.cancel();

        if (error.status === 401 && error.error?.detail?.toLowerCase().includes('token')) {
          LocalStorageAuthTokenKey.deleteToken();
          this.confirm
            .open({
              title: error.error?.title || this.transloco.translate('Unauthorized'),
              message: error.error?.detail || this.transloco.translate('An unknown error occurred.'),
              buttons: [{ text: this.transloco.translate('OK') }],
            })
            .subscribe(() => (window.location.href = '/login'));
        }

        this.store.dispatch(UiActions.setGlobalError({ error }));
        return throwError(() => error);
      })
    );
  }
}
