/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { MedicationForm } from './wasting.model';

export const COLLECTION_PROBABILITY_MIN = 0;
export const COLLECTION_PROBABILITY_MAX = 100;

export enum FormularyWitnessRequirement {
  'None' = 0,
  'Live' = 1,
  'Anytime' = 2,
}

export enum DEAScheduleKey {
  None,
  I,
  II,
  III,
  IV,
  V,
}

export interface Formulary {
  id: number;
  itemCode: string;
  supplyVendorNum: string | null;
  supplyTradeName: string;
  supplyGenericName: string;
  supplyForm: string;
  supplyFormEnum: MedicationForm;
  manufacturerPartNum: string;
  supplySize: number;
  supplySizeUnits: string;
  supplyStrength: number | null;
  supplyStrengthUnit: string | null;
  supplyCost: number | null;
  supplyNDCCode: string | null;
  deaScheduleKey: DEAScheduleKey;
  formularyWitnessRequirement: FormularyWitnessRequirement;
  isHazardous: boolean;
  collectionProbability: number;
}

// DTO for central pharmacy samples, similar to Formulary
// Could possibly share common interface in future
export interface Medication {
  id: number;
  itemCode: string;
  medGenericName: string;
  medBrandName: string;
  supplyForm: string;
  supplyFormEnum: MedicationForm;
  supplySize: number;
  supplySizeUnits: string;
  supplyStrength: number | null;
  supplyStrengthUnit: string | null;
  isHazardous: boolean;
  formularyWitnessRequirement: FormularyWitnessRequirement;
}

export interface ServiceLists {
  id: number;
  name: string;
  medicationName: string;
}
