/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Inject, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { API_URL, LocalStorageAuthTokenKey } from '@midas/shared/common';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable, Subject, finalize, from, switchMap, take, tap } from 'rxjs';
import { CurrentOrgSelectors } from './stores/current-org';

@Injectable({ providedIn: 'root' })
export class SignalrService {
  constructor(
    @Inject(API_URL) private apiUrl: string,
    private store: Store,
    private transloco: TranslocoService
  ) {}

  private connections = new Map<string, signalR.HubConnection>();

  async getConnection(route: string) {
    if (this.connections.has(route)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.connections.get(route)!;
    }

    // No need to have this be an observable, since if we change organizations, the page reloads
    const org = await this.store.select(CurrentOrgSelectors.getCurrentOrg).pipe(take(1)).toPromise();

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(
        `${this.apiUrl}${route}?access_token=${LocalStorageAuthTokenKey.authToken}&x_organization=${
          org?.name
        }&accept_language=${this.transloco.getActiveLang()}`
      )
      .configureLogging(signalR.LogLevel.Warning)
      .build();

    await connection
      .start()
      .then(() => console.log('Signalr Connection started'))
      .catch((err) => console.log('Error while starting connection: ' + err));

    this.connections.set(route, connection);

    return connection;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  broadcast(route: string, method: string, ...args: any[]) {
    return from(this.getConnection(route)).pipe(
      tap((connection) => {
        connection.invoke(method, ...args);
      })
    );
  }

  listen<T>(route: string, method: string): Observable<T> {
    return from(this.getConnection(route)).pipe(
      switchMap((connection) => {
        const subject = new Subject<T>();

        connection.on(method, (data: T) => {
          subject.next(data);
        });

        return subject.asObservable().pipe(
          finalize(() => {
            // Prevent memory leaks
            connection.off(method);
          })
        );
      })
    );
  }
}
