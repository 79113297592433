/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
export enum LocationPermissions {
  /* Direct location access */

  /** Allowed at the location at which the user is part of the user group */
  CanWaste = 1,

  /** Allowed at the location at which the user is part of the user group */
  CanWitness = 2,

  /** Allowed at the location at which the user is part of the user group */
  CanViewHistoricalWastes = 4,

  /** Allowed at the location at which the user is part of the user group */
  CanEmptyBins = 8,

  /** Allowed at the location at which the user is part of the user group */
  CanManageCarts = 16,

  /* Children locations access only */

  /** Allowed only at the children locations at which the user is part of the user group */
  CanManageLocations = 32,

  /** Allowed only at the children locations at which the user is part of the user group */
  CanManageUserGroups = 64,

  /* Direct location access */

  /** Allowed at the location at which the user is part of the user group */
  CanResolve = 128,

  /** Allowed to transport the collected Rx Intercepts to Central Pharmacy */
  CanTransport = 256,

  /** Allowed to final waste an RxIntercept in Central Pharmacy */
  CanFinalWaste = 512,

  /** Allowed to witness an RxIntercept being destroyed in Central Pharmacy as a final waste */
  CanWitnessFinalWaste = 1024,

  /** Allowed to witness transports */
  CanWitnessTransport = 2048,

  /** Allowed to view historical transports */
  CanViewHistoricalTransport = 4096,

  /** Allowed to resolve the transport records */
  CanResolveTransport = 8192,

  /** Allowed to view historical records of RxIntercepts destroyed in Central Pharmacy as a final waste */
  CanViewFinalWaste = 16384,

  /** Allowed to resolve the final waste reccords */
  CanResolveFinalWaste = 32768,
}

export const PharmacyPermissions = [
  LocationPermissions.CanWaste,
  LocationPermissions.CanWitness,
  LocationPermissions.CanViewHistoricalWastes,
  LocationPermissions.CanTransport,
  LocationPermissions.CanWitnessTransport,
  LocationPermissions.CanViewHistoricalTransport,
  LocationPermissions.CanFinalWaste,
  LocationPermissions.CanWitnessFinalWaste,
  LocationPermissions.CanViewFinalWaste,
];

export const DOP_Permissions = [
  ...PharmacyPermissions,
  LocationPermissions.CanResolve,
  LocationPermissions.CanResolveTransport,
  LocationPermissions.CanResolveFinalWaste,
];
