/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
export enum AdmType {
  Pyxis = 1,
  Omnicell = 2,
}

export interface Adm {
  id: number;
  name: string | null;
  description: string | null;
  type: AdmType;
  ipAddress: string | null;
  port: string | null;
  default: boolean | null;
  createdAt: string;
  updatedAt: string | null;
}
