/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Injectable } from '@angular/core';
import { ApiService, UserLocationPermission } from '@midas/shared/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { map, of } from 'rxjs';
import * as UserLocationPermissionsActions from './user-location-permissions.actions';

@Injectable()
export class UserLocationPermissionsEffects {
  readonly getUserLocationPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserLocationPermissionsActions.getUserLocationPermissions),
      fetch({
        id: () => true, // Makes `fetch` act like switchMap (cancels previous request)
        run: () =>
          this.api.get<UserLocationPermission[]>('/user/location-permissions').pipe(
            map((userPermissions) => {
              return UserLocationPermissionsActions.getUserLocationPermissionsSuccess({ userPermissions });
            })
          ),
        onError: (action, error) => {
          return of(UserLocationPermissionsActions.getUserLocationPermissionsError({ error }));
        },
      })
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
