/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { HttpBackend, HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UiActions } from '@midas/shared/common';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { EMPTY, catchError, finalize, map, of, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor(
    private snackBar: MatSnackBar,
    private handler: HttpBackend,
    private loadingBar: LoadingBarService,
    private store: Store,
    private transloco: TranslocoService
  ) {}

  upload(url: string, file: File, onSuccessText?: string) {
    this.loadingBar.useRef('router').start();
    this.snackBar.open(this.transloco.translate('Uploading...'));
    const http = new HttpClient(this.handler);
    return http
      .put(url, file, {
        observe: 'events',
        reportProgress: true,
      })
      .pipe(
        switchMap((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              {
                const progress = Math.round((100 * event.loaded) / (event.total ?? 1));
                this.loadingBar.useRef('router').set(progress);
              }
              break;
            case HttpEventType.Response:
              return of(null);
          }
          return EMPTY;
        }),
        map(() => {
          this.store.dispatch(
            UiActions.setGlobalSuccess({
              success: onSuccessText || this.transloco.translate('Upload Complete!'),
            })
          );
        }),
        catchError((error) => {
          this.store.dispatch(
            UiActions.setGlobalError({
              error: {
                error: { title: this.transloco.translate('Failed to upload'), detail: error.toString() },
              },
            })
          );
          throw error;
        }),
        finalize(() => {
          this.loadingBar.useRef('router').complete();
          this.snackBar.dismiss();
        })
      );
  }
}
