/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Transport } from "./transport.model";
import { Wasting, WastingType, WorkFlowStatus } from "./wasting.model";

export enum WorkflowType
{
    Transport,
    CentralPharmacy
}

export interface IWorkflow {
    id: number;
    workflowStatus: WorkFlowStatus;
    videoUploadComplete: boolean;
    videoFilePath: string | null;
    videoURL: string | null;
    localFilePath: string;
    videoCheckSum: string | null;
    is360Video: boolean;
    showWitnessVideo: boolean;
}

export interface VideoUrlsResponse {
    videoReadUrl: string;
    videoDownloadUrl: string;
    videoCheckSumFail: boolean;
    witnessVideoReadUrl: string;
    witnessVideoDownloadUrl: string;
    screenVideoReadUrl: string;
    screenVideoDownloadUrl: string;
    wastingThumbnailReadUrl: string;
  }

export function isTransport(item: any): item is Transport {
    return 'transportIdentifier' in item;
}

export function isWasting(item: any): item is Wasting {
    return 'wasteUser' in item;
}

export function isStandardWaste(item: any): item is Wasting {
    return item.wastingType === WastingType.Standard;
}