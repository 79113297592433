/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  userLocationPermissionsAdapter,
  UserLocationPermissionsState,
  USER_LOCATION_PERMISSIONS_KEY,
} from './user-location-permissions.reducer';

const { selectAll } = userLocationPermissionsAdapter.getSelectors();

const getLocationsState = createFeatureSelector<UserLocationPermissionsState>(USER_LOCATION_PERMISSIONS_KEY);

export const getUserPermissions = createSelector(
  getLocationsState,
  (state: UserLocationPermissionsState) => ({
    userLocationPermissions: selectAll(state.userLocationPermissions),
    loaded: state.loaded,
    loading: state.loading,
  })
);
