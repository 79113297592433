<!-- Copyright 2023 Midas Healthcare Solutions - All Rights Reserved -->
<mat-form-field class="example-chip-list" appearance="fill" *transloco="let t">
  <mat-label>{{ t(label) }}</mat-label>
  <mat-chip-grid #chipGrid>
    <mat-chip-row *ngFor="let user of items" (removed)="remove(user)">
      {{ user.lastName }}, {{ user.firstName }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input
    #searchInput
    [formControl]="searchCtrl"
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let user of filteredItems | async" [value]="user">
      {{ user.lastName }}, {{ user.firstName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
