/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../tokens';

interface QueryParams {
  [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> | undefined | null;
}

export interface ApiError {
  status?: number;
  message?: string;
  error?: {
    title?: string;
    detail?: string;
    exception?: string;
    instance?: string;
    stackTrace?: string;
    traceId?: string;
    type?: string;
  };
}

/**
 * Wrapper around HttpClient as to provide needed headers and base url for all requests.
 */
@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(private http: HttpClient, @Inject(API_URL) private apiUrl: string) {}

  get<T>(path: string, query?: QueryParams, headers?: { [header: string]: string | string[] }) {
    const qp = this.createQueryParamsString(query);
    return this.http.get<T>(`${this.apiUrl}${path}${qp}`, { headers });
  }

  post<T>(
    path: string,
    body: object,
    query?: QueryParams,
    headers?: { [header: string]: string | string[] }
  ) {
    const qp = this.createQueryParamsString(query);
    return this.http.post<T>(`${this.apiUrl}${path}${qp}`, body, { headers });
  }

  put<T>(path: string, body: object, query?: QueryParams, headers?: { [header: string]: string | string[] }) {
    const qp = this.createQueryParamsString(query);
    return this.http.put<T>(`${this.apiUrl}${path}${qp}`, body, { headers });
  }

  patch<T>(
    path: string,
    body: object,
    query?: QueryParams,
    headers?: { [header: string]: string | string[] }
  ) {
    const qp = this.createQueryParamsString(query);
    return this.http.patch<T>(`${this.apiUrl}${path}${qp}`, body, { headers });
  }

  delete<T>(path: string, query?: QueryParams, headers?: { [header: string]: string | string[] }) {
    const qp = this.createQueryParamsString(query);
    return this.http.delete<T>(`${this.apiUrl}${path}${qp}`, { headers });
  }

  private createQueryParamsString(query?: QueryParams) {
    if (query && Object.keys(query).length > 0) {
      // remove null and undefined values
      Object.keys(query).forEach(
        (key) => (query[key] === undefined || query[key] === null) && delete query[key]
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const qp = new HttpParams({ fromObject: query as any }).toString();
      return `?${qp}`;
    }
    return '';
  }
}
