/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ApiService, Patient } from '@midas/shared/common';
import { TranslocoModule } from '@ngneat/transloco';
import { map, Observable } from 'rxjs';
import { IQuerySelect } from '../ui-query-select';

@Component({
  selector: 'midas-ui-patient-query-select',
  templateUrl: './ui-patient-query-select.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatButtonModule,
    TranslocoModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiPatientQuerySelectComponent),
      multi: true,
    },
  ],
})
export class UiPatientQuerySelectComponent extends IQuerySelect<Patient> {
  constructor(private api: ApiService, protected _change: ChangeDetectorRef) {
    super(_change);
  }

  getItemsBySearchString(searchString?: string | null): Observable<Patient[]> {
    return this.api
      .get<{ count: number; patients: Patient[] }>('/patient', {
        offset: 0,
        limit: 5,
        searchString: searchString,
      })
      .pipe(map((res) => res.patients));
  }

  getItemsByIds(ids: number[]): Observable<Patient[]> {
    return this.api
      .get<{ count: number; patients: Patient[] }>('/patient', {
        offset: 0,
        limit: 100,
        ids: ids,
      })
      .pipe(map((res) => res.patients));
  }
}
