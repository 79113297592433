/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurrentOrgState, CURRENT_ORG_KEY, orgAdapter } from './current-org.reducer';

const { selectAll } = orgAdapter.getSelectors();

const getState = createFeatureSelector<CurrentOrgState>(CURRENT_ORG_KEY);
export const getCurrentOrgState = createSelector(getState, (state: CurrentOrgState) => state);
export const getCurrentOrg = createSelector(getState, (state: CurrentOrgState) =>
  selectAll(state.orgs).find((org) => org.name === state.currentOrgName)
);
