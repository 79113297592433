/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Cart } from './cart.model';

export interface CollectionBin {
  id: number;
  cart: Cart;
  serialNumber: string;
  collectionBinType: CollectionBinType;
  capacity: number;
  wastedCount: number;
  installVideoFilePath: string;
  removalVideoFilePath: string | null;
  timeInstalled: string;
  timeRemoved: string | null;
  isActive: boolean;
  createdAt: string;
  updatedAt: string | null;
}

export enum CollectionBinType {
  NonHazardous,
  Hazardous,
  Collection,
}
