/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { MidasAdminOrganizationPermissions, MidasEffectError, Organization } from '@midas/shared/common';
import { createAction, props } from '@ngrx/store';

export const getCurrentOrg = createAction(
  '[Current Org] Get Current Org',
  props<{ organizationName: string }>()
);
export const getCurrentOrgSuccess = createAction(
  '[Current Org] Get Current Org Success',
  props<{ org: Organization }>()
);
export const getCurrentOrgError = createAction(
  '[Current Org] Get Current Org Error',
  props<MidasEffectError>()
);

export const setCurrentOrg = createAction(
  '[Current Org] Set Current Org',
  props<{ organizationName: string }>()
);

export const unLoadCurrentOrg = createAction('[Current Org] UnLoad Current Org ');

export const updateCurrentOrg = createAction(
  '[Current Org] Update Current Org',
  props<{
    orgId: number;
    name: string;
    midasAdminOrganizationPermissions?: MidasAdminOrganizationPermissions;
    address?: string;
    city?: string;
    state?: string;
    zip?: number;
    cartAutoLogoutTime?: number;
    contactName?: string;
    contactPhone?: string;
    contactEmail?: string;
    accountNumber: string;
  }>()
);
export const updateCurrentOrgSuccess = createAction(
  '[Current Org] Update Current Org Success',
  props<{ org: Organization }>()
);
export const updateCurrentOrgError = createAction(
  '[Current Org] Update Current Org Error',
  props<MidasEffectError>()
);
