/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'customIcon', standalone: true })
export class CustomIconPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  transform(name: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/${name}.svg`);
  }
}
