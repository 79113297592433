/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { map, Observable, takeUntil } from 'rxjs';
import { RxJSBaseClass } from './rxjs-base-class';

@Injectable()
export class ScreenSizeService extends RxJSBaseClass {
  private displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);

  readonly screenSize$: Observable<'XSmall' | 'Small' | 'Medium' | 'Large' | 'XLarge' | null> =
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(
        map((result) => {
          for (const query of Object.keys(result.breakpoints)) {
            if (result.breakpoints[query]) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              return (this.displayNameMap.get(query) as any) ?? null;
            }
          }
          return null;
        })
      );

  readonly desktop$ = this.screenSize$.pipe(
    map((s) => s === 'Medium' || s === 'Large' || s === 'XLarge' || !s)
  );
  readonly mobile$ = this.desktop$.pipe(map((v) => !v));

  desktop = false;
  mobile = false;

  constructor(private breakpointObserver: BreakpointObserver, private change: ChangeDetectorRef) {
    super();
    this.desktop$.pipe(takeUntil(this.destroy$)).subscribe((v) => {
      this.desktop = v;
      this.mobile = !v;
      this.change.markForCheck();
    });
  }
}
