/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { createAction, props } from '@ngrx/store';
import { ApiError } from '../services';
import { UiTheme } from './ui.models';

export const setTheme = createAction('[Ui] Set Theme', props<{ theme: UiTheme }>());

export const setGlobalError = createAction('[Ui] Set Global Error', props<{ error: ApiError }>());
export const setGlobalSuccess = createAction('[Ui] Set Global Success', props<{ success: string }>());
export const clearGlobalStatus = createAction('[Ui] Clear Global Status');
