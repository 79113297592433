/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { UserLocationPermission } from '@midas/shared/common';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import * as UserPermissionsActions from './user-location-permissions.actions';

export const USER_LOCATION_PERMISSIONS_KEY = 'userPermissions';

export interface UserLocationPermissionsState {
  userLocationPermissions: EntityState<UserLocationPermission>;
  loaded: boolean;
  loading: boolean;
}

export const userLocationPermissionsAdapter = createEntityAdapter<UserLocationPermission>({
  selectId: (userLocationPermission) => userLocationPermission.locationId.toString(),
});

const initialState: UserLocationPermissionsState = {
  userLocationPermissions: userLocationPermissionsAdapter.getInitialState(),
  loaded: false,
  loading: false,
};

export const UserPermissionsFeature = createFeature({
  name: USER_LOCATION_PERMISSIONS_KEY,
  reducer: createReducer(
    initialState,
    on(UserPermissionsActions.getUserLocationPermissions, (state): UserLocationPermissionsState => {
      return {
        ...state,
        loading: true,
      };
    }),
    on(
      UserPermissionsActions.getUserLocationPermissionsSuccess,
      (state, { userPermissions }): UserLocationPermissionsState => {
        return {
          userLocationPermissions: userLocationPermissionsAdapter.setAll(
            userPermissions,
            state.userLocationPermissions
          ),
          loaded: true,
          loading: false,
        };
      }
    )
  ),
});
