/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageAuthTokenKey, UiActions } from '@midas/shared/common';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { CurrentOrgSelectors } from '../stores/current-org';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  constructor(private store: Store, private transloco: TranslocoService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.store.select(CurrentOrgSelectors.getCurrentOrg).pipe(
      take(1),
      map((currOrgState) => {
        this.store.dispatch(UiActions.clearGlobalStatus());

        let headers = request.headers;

        const name = currOrgState?.name;
        if (name && !headers.has('X-Organization')) {
          headers = headers.set('X-Organization', name);
        }

        const token = LocalStorageAuthTokenKey.authToken;
        if (token) {
          headers = headers.set('Authorization', `Bearer ${token}`);
        }

        headers = headers.set('Accept-Language', this.transloco.getActiveLang());

        return request.clone({ headers });
      }),
      switchMap((requestWithHeaders) => next.handle(requestWithHeaders)),
    );
  }
}
