/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ApiService, ManagedUser } from '@midas/shared/common';
import { TranslocoModule } from '@ngneat/transloco';
import { map, Observable } from 'rxjs';
import { IQuerySelect } from '../ui-query-select';

@Component({
  selector: 'midas-ui-user-query-select',
  templateUrl: './ui-user-query-select.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatButtonModule,
    TranslocoModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiUserQuerySelectComponent),
      multi: true,
    },
  ],
})
export class UiUserQuerySelectComponent extends IQuerySelect<ManagedUser> {
  @Input() label = 'Filter by User';
  @Input() showCurrentUser = true;

  constructor(private api: ApiService, protected _change: ChangeDetectorRef) {
    super(_change);
  }

  getItemsBySearchString(searchString?: string | null): Observable<ManagedUser[]> {
    return this.api
      .get<{ count: number; managedUsers: ManagedUser[] }>('/managed-user', {
        offset: 0,
        limit: 5,
        searchString: searchString,
        showCurrentUser: this.showCurrentUser
      })
      .pipe(map((res) => res.managedUsers));
  }

  getItemsByIds(ids: number[]): Observable<ManagedUser[]> {
    return this.api
      .get<{ count: number; managedUsers: ManagedUser[] }>('/managed-user', {
        offset: 0,
        limit: 100,
        ids: ids,
      })
      .pipe(map((res) => res.managedUsers));
  }
}
