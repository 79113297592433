/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Store } from '@ngrx/store';
import { auditTime, map } from 'rxjs';
import { UiSelectors } from '../ui';

@Component({
  selector: 'midas-init-loader',
  templateUrl: './init-loader.component.html',
  styleUrls: ['./init-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('loader', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in-out', style({ opacity: 0, transform: 'scale(1.1)' })),
      ]),
    ]),
  ],
  standalone: true,
  imports: [MatProgressSpinnerModule, AsyncPipe],
})
export class InitLoaderComponent {
  @HostBinding('@loader') animation = true;

  readonly theme$ = this.store.select(UiSelectors.getUiState).pipe(
    auditTime(0),
    map((state) => state.theme)
  );

  constructor(private store: Store) {}
}
