<!-- Copyright 2023 Midas Healthcare Solutions - All Rights Reserved -->
<h2>{{ data.title }}</h2>

<div class="cont" [innerHTML]="data.message"></div>
<br />

<ng-container *ngIf="data.requireDeleteInput as rdi">
  <span *transloco="let t">
    {{ t('Type') }}
    <b>{{ rdi.text }}</b>
    {{ t('in the input field to delete.') }}
  </span>

  <br />
  <br />

  <mat-form-field appearance="fill" *ngIf="data.requireDeleteInput as rdi">
    <mat-label>{{ rdi.text }}</mat-label>
    <input matInput [formControl]="deleteCtrl" />
    <button mat-icon-button matSuffix *ngIf="deleteCtrl.value" (click)="deleteCtrl.patchValue('')">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</ng-container>

<div class="buttons">
  <ng-container *ngFor="let b of data.buttons; index as i">
    <button
      mat-flat-button
      [disabled]="
        data.requireDeleteInput &&
        data.requireDeleteInput.buttonIndex === i &&
        data.requireDeleteInput.text !== deleteCtrl.value
      "
      [color]="b.color"
      (click)="dialogRef.close(i)"
    >
      {{ b.text }}
    </button>
  </ng-container>
</div>
