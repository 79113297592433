/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AuthActions } from '@midas/shared/data-access';
import { UtilHttpProcessingOverlayDirective } from '@midas/shared/util';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { timer } from 'rxjs';
import { UiStatusBlockComponent } from '../ui-status-block/ui-status-block.component';
import { IdleTimerService } from './idle-timer.service';

@Component({
  selector: 'midas-auto-logout-dialog',
  templateUrl: './auto-logout-dialog.component.html',
  styleUrls: ['./auto-logout-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    UiStatusBlockComponent,
    DragDropModule,
    TranslocoModule,
    UtilHttpProcessingOverlayDirective,
  ],
})
export class AutoLogoutDialogComponent implements OnInit {
  constructor(
    private store: Store,
    private idleTimer: IdleTimerService,
    private change: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AutoLogoutDialogComponent>
  ) {}

  countdownText = '';

  ngOnInit(): void {
    const idleTimer = timer(0, 1000).subscribe(() => {
      const secondsRemaining = this.idleTimer.getSecondsRemaining();
      const m = Math.floor(secondsRemaining / 60) > 0 ? Math.floor(secondsRemaining / 60).toString() : '0';
      const s = Math.floor(secondsRemaining % 60)
        .toString()
        .padStart(2, '0');
      this.setCountdownText(m + ':' + s);

      if (secondsRemaining === 0) {
        idleTimer.unsubscribe();
      }
    });
  }

  private setCountdownText(text: string) {
    this.countdownText = text;
    this.change.markForCheck();
  }

  continue() {
    this.dialogRef.close();
  }

  logout() {
    this.store.dispatch(AuthActions.logoutLocal());
  }
}
