/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { ApplicationRef, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent } from 'rxjs';
import { setTheme } from './ui.actions';

@Injectable({ providedIn: 'root' })
export class UiService {
  constructor(private store: Store, private ref: ApplicationRef) {}

  listenTheme() {
    const cachedTheme = localStorage.getItem('data-theme');
    if (cachedTheme) {
      const theme = cachedTheme === 'dark' ? 'dark' : 'light';
      this.store.dispatch(setTheme({ theme }));
      return;
    }

    // Initially check if dark mode is enabled on system
    const darkModeOn = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    // If dark mode is enabled then directly switch to the dark-theme
    if (darkModeOn) {
      this.store.dispatch(setTheme({ theme: 'dark' }));
    } else {
      this.store.dispatch(setTheme({ theme: 'light' }));
    }

    // Watch for changes of the preference
    // No unsubscribe needed as this is a root service
    fromEvent(window.matchMedia('(prefers-color-scheme: dark)'), 'change').subscribe((e) => {
      const turnOn = (e as MediaQueryListEvent).matches;
      if (turnOn) {
        this.store.dispatch(setTheme({ theme: 'dark' }));
      } else {
        this.store.dispatch(setTheme({ theme: 'light' }));
      }
      // Trigger refresh of UI
      this.ref.tick();
    });
  }
}
