/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Injectable } from '@angular/core';
import { ApiService, LocalStorageAuthTokenKey, User } from '@midas/shared/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch, pessimisticUpdate } from '@nrwl/angular';
import { map, of } from 'rxjs';
import * as UserActions from './user.actions';

@Injectable()
export class UserEffects {
  readonly getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getProfile),
      fetch({
        id: () => true, // Makes `fetch` act like switchMap (cancels previous request)
        run: () =>
          this.api.get<User>('/user').pipe(
            map((user) => {
              return UserActions.getProfileSuccess({ user });
            })
          ),
        onError: (action, error) => {
          LocalStorageAuthTokenKey.deleteToken();
          return of(UserActions.getProfileError({ error }));
        },
      })
    )
  );

  readonly setUserSmsMfa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setUserSmsMfa),
      pessimisticUpdate({
        run: (dto) =>
          this.api.post('/user/set-sms-mfa', dto).pipe(
            map(() => {
              return UserActions.setUserSmsMfaSuccess();
            })
          ),
        onError: (action, error) => {
          return UserActions.setUserSmsMfaError({ error });
        },
      })
    )
  );

  readonly verifyUserSmsMfa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.verifyUserSmsMfa),
      pessimisticUpdate({
        run: (dto) =>
          this.api.post<User>('/user/verify-sms-mfa', dto).pipe(
            map((user) => {
              return UserActions.verifyUserSmsMfaSuccess({ user });
            })
          ),
        onError: (action, error) => {
          return UserActions.verifyUserSmsMfaError({ error });
        },
      })
    )
  );

  readonly disableUserMfa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.disableUserMfa),
      pessimisticUpdate({
        run: (dto) =>
          this.api.post<{ action: string; session: string }>('/user/disable-mfa', dto).pipe(
            map(({ action, session }) => {
              return UserActions.disableUserMfaSuccess({ action, session });
            })
          ),
        onError: (action, error) => {
          return UserActions.disableUserMfaError({ error });
        },
      })
    )
  );

  readonly verifyDisableUserMfa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.verifyDisableUserMfa),
      pessimisticUpdate({
        run: (dto) =>
          this.api.post<{ token: string; user: User }>('/user/verify-disable-mfa', dto).pipe(
            map(({ user, token }) => {
              LocalStorageAuthTokenKey.authToken = token;
              return UserActions.verifyDisableUserMfaSuccess({ user });
            })
          ),
        onError: (action, error) => {
          return UserActions.verifyDisableUserMfaError({ error });
        },
      })
    )
  );

  readonly updateProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateProfile),
      pessimisticUpdate({
        run: (dto) =>
          this.api
            .put<{
              username: string;
              firstName: string | null;
              lastName: string | null;
              email: string | null;
            }>('/user/update-profile', dto)
            .pipe(
              map(({ firstName, lastName, email }) => {
                return UserActions.updateProfileSuccess({ firstName, lastName, email });
              })
            ),
        onError: (action, error) => {
          return UserActions.updateProfileError({ error });
        },
      })
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
