/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@ngneat/transloco';

export interface UtilConfirmDialogData {
  title: string;
  message: string;
  requireDeleteInput?: { text: string; buttonIndex: number };
  buttons: { text: string; color?: string }[];
}

@Component({
  selector: 'util-confirm-dialog',
  templateUrl: './util-confirm-dialog.component.html',
  styleUrls: ['./util-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatDialogModule,
    TranslocoModule,
  ],
})
export class UtilConfirmDialogComponent {
  readonly deleteCtrl = new FormControl<string>('', { nonNullable: true });

  constructor(
    public dialogRef: MatDialogRef<UtilConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UtilConfirmDialogData
  ) {}
}
