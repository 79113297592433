<!-- Copyright 2023 Midas Healthcare Solutions - All Rights Reserved -->
<mat-form-field class="example-chip-list" appearance="fill" *transloco="let t">
  <mat-label>{{ t('Filter by Location') }}</mat-label>
  <mat-chip-grid #chipGrid>
    <mat-chip-row *ngFor="let loc of items" (removed)="remove(loc)">
      {{ loc.locationName }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input
    #searchInput
    [formControl]="searchCtrl"
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let loc of filteredItems | async" [value]="loc">
      {{ loc.locationName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
