/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { LocationPermissions } from '@midas/shared/common';
import { UserPermissionsActions, UserPermissionsSelectors } from '@midas/shared/data-access';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';
import { IQuerySelect } from '../ui-query-select';

interface UserLocationPermissionId {
  id: number;
  locationName: string;
  permissions: LocationPermissions;
}

@Component({
  selector: 'midas-ui-location-query-select',
  templateUrl: './ui-location-query-select.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatButtonModule,
    TranslocoModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiLocationQuerySelectComponent),
      multi: true,
    },
  ],
})
export class UiLocationQuerySelectComponent extends IQuerySelect<UserLocationPermissionId> {
  constructor(private store: Store, protected _change: ChangeDetectorRef) {
    super(_change);
  }

  getItemsBySearchString(searchString?: string | null): Observable<UserLocationPermissionId[]> {
    return this.store.select(UserPermissionsSelectors.getUserPermissions).pipe(
      filter(({ loaded }) => {
        if (!loaded) {
          this.store.dispatch(UserPermissionsActions.getUserLocationPermissions());
        }
        return loaded;
      }),
      map(({ userLocationPermissions }) => {
        return userLocationPermissions
          .filter(
            (p) =>
              p.permissions & LocationPermissions.CanViewHistoricalWastes &&
              p.locationName.toLowerCase().includes(searchString?.toLowerCase() ?? '')
          )
          .map(
            (p): UserLocationPermissionId => ({
              ...p,
              id: p.locationId,
            })
          );
      })
    );
  }

  getItemsByIds(ids: number[]): Observable<UserLocationPermissionId[]> {
    return this.store.select(UserPermissionsSelectors.getUserPermissions).pipe(
      filter(({ loaded }) => {
        if (!loaded) {
          this.store.dispatch(UserPermissionsActions.getUserLocationPermissions());
        }
        return loaded;
      }),
      map(({ userLocationPermissions }) => {
        return userLocationPermissions
          .filter((p) => p.permissions & LocationPermissions.CanViewHistoricalWastes && ids.includes(p.locationId))
          .map(
            (p): UserLocationPermissionId => ({
              ...p,
              id: p.locationId,
            })
          );
      })
    );
  }
}
