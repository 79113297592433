/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { createFeature, createReducer, on } from '@ngrx/store';
import { ApiError } from '../services';
import * as UiActions from './ui.actions';
import { UiTheme } from './ui.models';

export const UI_KEY = 'ui';

export interface UiState {
  theme: UiTheme;
  error: ApiError | null;
  success: string | null;
}

const initialState: UiState = {
  theme: 'light',
  error: null,
  success: null,
};

export const UiFeature = createFeature({
  name: UI_KEY,
  reducer: createReducer(
    initialState,
    on(UiActions.setTheme, (state, { theme }): UiState => {
      localStorage.setItem('data-theme', theme);
      document.documentElement.setAttribute('data-theme', theme);
      return { ...state, theme };
    }),
    on(UiActions.setGlobalError, (state, { error }): UiState => {
      return { ...state, error, success: null };
    }),
    on(UiActions.setGlobalSuccess, (state, { success }): UiState => {
      return { ...state, success, error: null };
    }),
    on(UiActions.clearGlobalStatus, (state): UiState => {
      return { ...state, error: null, success: null };
    })
  ),
});
