/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ApiError, UiActions } from '@midas/shared/common';
import { RxJSBaseClass } from '@midas/shared/util';
import { TranslocoModule } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { ROUTER_REQUEST } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { merge, takeUntil } from 'rxjs';

@Component({
  selector: 'midas-ui-status-block',
  templateUrl: './ui-status-block.component.html',
  styleUrls: ['./ui-status-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatButtonModule, MatIconModule, TranslocoModule],
})
export class UiStatusBlockComponent extends RxJSBaseClass implements OnInit {
  error!: ApiError | null;

  success!: string | null;

  @HostBinding('class.show')
  shown = false;

  @Input()
  @HostBinding('class')
  appearance: 'block' | 'inline' = 'block';

  constructor(
    private store: Store,
    private actions: Actions,
    private change: ChangeDetectorRef,
    private el: ElementRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.actions.pipe(ofType(UiActions.setGlobalError), takeUntil(this.destroy$)).subscribe(({ error }) => {
      this.error = error;
      this.success = null;
      this.shown = !!error;
      this.change.markForCheck();
    });

    this.actions
      .pipe(ofType(UiActions.setGlobalSuccess), takeUntil(this.destroy$))
      .subscribe(({ success }) => {
        this.error = null;
        this.success = success;
        this.shown = !!success;
        this.change.markForCheck();
      });

    merge(this.actions.pipe(ofType(UiActions.clearGlobalStatus)), this.actions.pipe(ofType(ROUTER_REQUEST)))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.shown = false;
        this.change.markForCheck();
      });

    // // Detect if el is in front of other elements
    // // If not, clear the error
    // const observer = new IntersectionObserver(([el]) => {
    //   const rect = el.target.getBoundingClientRect();
    //   const x = rect.left;
    //   const y = rect.top;

    //   const topEl = document.elementFromPoint(x, y);

    //   if (topEl !== this.el.nativeElement) {
    //     this.error = null;
    //     this.success = null;
    //     this.shown = false;
    //     this.change.markForCheck();
    //   }
    // });

    // observer.observe(this.el.nativeElement);
  }

  clear() {
    this.store.dispatch(UiActions.clearGlobalStatus());
  }
}
