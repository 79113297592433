/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Organization } from '@midas/shared/common';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import * as OrgActions from './current-org.actions';

export const CURRENT_ORG_KEY = 'currentOrg';

export interface CurrentOrgState {
  orgs: EntityState<Organization>;
  currentOrgName: string | null;
  loadedOrgs: string[];
}

export const orgAdapter = createEntityAdapter<Organization>();

const initialState: CurrentOrgState = {
  orgs: orgAdapter.getInitialState(),
  currentOrgName: null,
  loadedOrgs: [],
};

export const CurrentOrgFeature = createFeature({
  name: CURRENT_ORG_KEY,
  reducer: createReducer(
    initialState,
    on(OrgActions.getCurrentOrgSuccess, (state, { org }): CurrentOrgState => {
      return {
        ...state,
        currentOrgName: org.name,
        orgs: orgAdapter.upsertOne(org, state.orgs),
        loadedOrgs: [...state.loadedOrgs, org.name],
      };
    }),
    on(OrgActions.setCurrentOrg, (state, { organizationName }): CurrentOrgState => {
      return {
        ...state,
        currentOrgName: organizationName,
      };
    }),
    on(OrgActions.updateCurrentOrgSuccess, (state, { org }): CurrentOrgState => {
      return {
        ...state,
        orgs: orgAdapter.updateOne(
          {
            id: org.id,
            changes: org,
          },
          state.orgs
        ),
      };
    }),
    on(OrgActions.unLoadCurrentOrg, (state): CurrentOrgState => ({ ...state, currentOrgName: null }))
  ),
});
