/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthActions, UserActions } from '@midas/shared/data-access';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { take, timer } from 'rxjs';
import { AutoLogoutDialogComponent } from './auto-logout-dialog.component';

@Injectable({ providedIn: 'root' })
export class IdleTimerService {
  private EXPIRE_TIME = '_expireTime';
  private TIMEOUT_MINUTES = 15;
  private DISPLAY_DIALOG_REMAINING_SECONDS = 60;
  private isDialogVisible = false;
  private pauseListener = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private eventHandler: any;
  private timeoutTracker!: ReturnType<typeof setTimeout>;

  constructor(private dialog: MatDialog, private store: Store, private actions: Actions) {
    this.eventHandler = this.updateExpiredTime.bind(this);
  }

  initializeIdleTimer() {
    this.actions.pipe(ofType(UserActions.getProfileSuccess), take(1)).subscribe(() => {
      console.log('Idle timer started.');
      this.setExpiredTime();
      this.startListeners();
      this.startInterval();
    });
  }

  getSecondsRemaining() {
    const expiredTime = parseInt(localStorage.getItem(this.EXPIRE_TIME) || '0');
    return expiredTime - Date.now() > 0 ? (expiredTime - Date.now()) / 1000 : 0;
  }

  private startListeners() {
    window.addEventListener('click', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
  }

  private stopListeners() {
    window.removeEventListener('click', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }

  private setExpiredTime() {
    localStorage.setItem(this.EXPIRE_TIME, (Date.now() + this.TIMEOUT_MINUTES * 60 * 1000).toString());
  }

  private startInterval() {
    this.updateExpiredTime();

    const idleTimer = timer(0, 1000).subscribe(() => {
      const remainingSeconds = this.getSecondsRemaining();
      if (remainingSeconds < this.DISPLAY_DIALOG_REMAINING_SECONDS && !this.isDialogVisible) {
        this.pauseListener = true;
        this.stopListeners();

        this.isDialogVisible = true;
        this.dialog.open(AutoLogoutDialogComponent, {
          panelClass: 'ui-dialog-window',
          maxWidth: '420px',
          disableClose: true,
        });
      }

      if (this.pauseListener && !this.isDialogVisible) {
        this.pauseListener = false;
        this.startListeners();
      }

      if (remainingSeconds <= 0) {
        idleTimer.unsubscribe();
        this.cleanUp();
        this.store.dispatch(AuthActions.logoutLocal());
      }
    });
  }

  private updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }

    this.timeoutTracker = setTimeout(() => this.setExpiredTime(), 1000);
  }

  private cleanUp() {
    this.isDialogVisible = false;
    localStorage.removeItem(this.EXPIRE_TIME);

    this.stopListeners();
  }
}
