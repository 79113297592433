/** Copyright 2023 Midas Healthcare Solutions - All Rights Reserved **/
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  skip,
  startWith,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { CurrentOrgSelectors } from './stores/current-org';

export interface Breadcrumb {
  label: string;
  url: string;
}

export const createBreadcrumb = (bcFn: (orgName: string, translate: (key: string) => string) => Breadcrumb) =>
  bcFn;

export const createBreadcrumbEntity = <T>(
  idFn: (id: number) => (state: object) => T,
  bcFn: (orgName: string, entity: T, translate: (key: string) => string) => Breadcrumb
) => ({ idFn, bcFn });

@Injectable({ providedIn: 'root' })
export class BreadcrumbsService {
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  get breadcrumbs$() {
    return this._breadcrumbs$.asObservable();
  }

  readonly refresh$ = new Subject<null>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private transloco: TranslocoService,
    private store: Store
  ) {}

  start() {
    combineLatest([
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route)
      ),
      inject(Store).select(CurrentOrgSelectors.getCurrentOrg),
      this.refresh$.pipe(startWith(null)),
    ])
      .pipe(
        tap(async ([route, org]) => {
          const breadcrumbs: Breadcrumb[] = [];

          while (route.firstChild) {
            route = route.firstChild;
          }

          while (route) {
            const breadcrumbEntityRtn = route.snapshot.data['breadcrumbEntity'] as ReturnType<
              typeof createBreadcrumbEntity
            > | null;

            if (breadcrumbEntityRtn) {
              const entityAsync = this.store.select(breadcrumbEntityRtn.idFn(route.snapshot.params['id']));

              // Refresh the breadcrumbs when the entity changes
              // The skip one here works like magic. Without it, an infinite loop will ensue
              entityAsync.pipe(skip(1), takeUntil(this.refresh$)).subscribe(() => {
                this.refresh$.next(null);
              });

              const entity = await entityAsync.pipe(take(1)).toPromise();
              const breadcrumb = breadcrumbEntityRtn.bcFn(
                org?.name || '',
                entity,
                this.transloco.translate.bind(this.transloco)
              );
              if (!breadcrumbs.some((bc) => bc.label === breadcrumb.label)) {
                breadcrumbs.unshift(breadcrumb);
              }
            }

            const breadcrumbRtn = route.snapshot.data['breadcrumb'] as ReturnType<
              typeof createBreadcrumb
            > | null;

            if (breadcrumbRtn) {
              const breadcrumb = breadcrumbRtn(
                org?.name || '',
                this.transloco.translate.bind(this.transloco)
              );
              if (!breadcrumbs.some((bc) => bc.label === breadcrumb.label)) {
                breadcrumbs.unshift(breadcrumb);
              }
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            route = route.parent!;
          }

          this._breadcrumbs$.next(breadcrumbs);
        })
      )
      .subscribe();
  }
}
